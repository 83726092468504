import React from "react"
import AuthCode from "react-auth-code-input"

const CodeInput = props => {
  const {
    className = "",
    characters = 4,
    onChange = () => null,
    size = "md",
  } = props
  return (
    <div className={`gt-code-input gt-code-input-${size} ${className}`}>
      <AuthCode characters={characters} onChange={onChange} />
    </div>
  )
}

export default CodeInput
