import React, { useEffect, useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory, useParams } from "react-router-dom"
import toast from "react-hot-toast"

import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import RequestItemsReceivingSection from "./RequestItemsReceivingSection"
import eyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import {
    GetRequestPOListing,
    GetWarehouseToFinanceRequestsListingItems,
} from "../../../api/api.service"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { AutoComplete } from "components/AutoComplete"
import {
    convertArrItemsToCommaSeparatedString,
    convertToSelectOptions,
    getAppName,
    handleSearch,
    handleStateSet,
} from "../../../utils/commonUtils"
import { Refresh } from "components/Refresh"
import { APP_ROUTES, getRoute } from "helpers/routeHelpers"
import { TextInput } from "components/TextInput"
import { InventoryReqItemsStatus } from "../../../constants"
import customStyles from "../SelectStyles"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import Config from "Config"

const COLUMNS = {
    SKU_NO: "SKU No.",
    PO_NO: "PO No.",
    ITEM_NAME: "Item Name",
    RECEIVED_QUANTITY: "Received Qty.",
    REQUEST_QUANTITY: "Requested Qty.",
    QUANTITY_SUPPLIED: "Quantity Supplied",
    SUBSTITUTE_ITEM: "Substitute Name",
    STATUS: "Status",
    ACTIONS: "Actions",
}

const dataKey = "data"
const totalKey = "total"

const getAllSelectedItems = (itemsArr = []) =>
    itemsArr?.filter(item => item.isChecked && item.eligibilityCheck)
const RequestsItemsListing = ({}) => {
    const controller = new AbortController()
    const signal = controller.signal
    const history = useHistory()
    const { requestId } = useParams()
    const [selectedPONumber, setSelectedPONumber] = useState("")
    const [statusFilters, setStatusFilters] = useState(null)
    const [modalToggle, setModalToggle] = useState(false)
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [posListing, setPOsListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })
    useEffect(() => {
        getPOsListingData(posListing.page)

        return () => {
            controller.abort()
        }
    }, [posListing.Q])

    const {
        data,
        page,
        pageSize,
        isFetching,
        searchText,
        total,
        setData,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetWarehouseToFinanceRequestsListingItems,
        params: {
            ...(statusFilters?.value && {
                status: statusFilters?.value,
            }),
            ...(selectedPONumber?.id && {
                purchaseOrderId: selectedPONumber?.id,
            }),
        },
        deps: [statusFilters, selectedPONumber],
        dataKey: dataKey,
        totalKey: totalKey,
        id: requestId,
    })

    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleInitMarkReceiving = () => {
        if (!data.find(item => item.isChecked && item.eligibilityCheck)) {
            return toast.error("No item has been selected !")
        }

        setModalToggle(!modalToggle)
    }

    const handleNavigateToRequestItemsReceiving = data => {
        history.push(
            getRoute(APP_ROUTES.REQUEST_MANAGEMENT_REQUESTS_ITEMS_RECEIVING, {
                requestItemId: data.id,
            })
        )
    }

    const handleSelectAll = () => {
        let afterChecked = data.map(item => ({
            ...item,
            isChecked: !isAllSelected,
            eligibilityCheck: isEligible(item),
        }))

        setIsAllSelected(prev => !prev)
        setData(afterChecked)
    }

    const handleCheckBoxSelect = (_, ind) => {
        let clone = [...data]
        if (clone[ind].isChecked) {
            clone[ind].isChecked = false
        } else {
            clone[ind].isChecked = true
        }

        clone[ind].eligibilityCheck = isEligible(clone[ind])

        setData(clone)
    }

    const isEligible = data => {
        let QTY_LIMIT = 0
        return data.quantitySupplied != QTY_LIMIT
    }

    const getPOsListingData = page => {
        handleStateSet(setPOsListing, { fetching: true, data: [] })
        GetRequestPOListing(
            {
                limit: Config.LIMIT,
                ...(posListing.Q && { Q: posListing.Q }),
                page: page,
            },
            signal,
            requestId
        )
            .then(res => {
                setPOsListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length === Config.LIMIT,
                    data: (() => {
                        let test = res?.data?.map(item => ({
                            ...item,
                            value: item.poNo,
                            label: item.poNo,
                        }))
                        return [...test, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setPOsListing, { fetching: false })
            })
    }

    const handleSelectPONumber = (option = "") => {
        setSelectedPONumber(option)
    }

    const handleRefresh = () => {
        getPOsListingData(posListing.page)
        request()
    }

    return (
        <React.Fragment>
            <div className="gt-business-accounts">
                <Container fluid>
                    <Row>
                        <Col className="col-12 px-lg-3 px-2 order-management-con">
                            <div className="d-flex align-items-center mb-4 mb-4 mt-lg-0 mt-3">
                                <BackButtonTableTop />
                            </div>
                            <div className="d-flex align-items-center top-heading-con mb-2 order-man-top-heading">
                                <TableTopHeading
                                    title={`Request Management (${getAppName()})`}
                                    className="me-4 top-heading-typo"
                                    iconImage={InventoryRequestIcon}
                                    iconClass="inventory-req-icon"
                                />
                            </div>

                            <Card className="mb-5">
                                <Refresh
                                    onClick={handleRefresh}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="fw-600 mb-0 table-main-heading">
                                            REQUEST ID: {requestId}
                                        </h5>
                                        <p className="fw-500">
                                            Order Date:{" "}
                                            {convertDateTime({
                                                date: data[0]?.createdAt,
                                                customFormat:
                                                    dateTimeFormat.ddmmyyyy,
                                                dateOnly: true,
                                            })}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                        <div className="d-flex gap-2 flex-wrap">
                                            <div>
                                                <AutoComplete
                                                    customStyles={
                                                        customStyles.WarehouseIM
                                                    }
                                                    handleBlur={() =>
                                                        setPOsListing(prev => {
                                                            return {
                                                                ...prev,
                                                                page: 1,
                                                                Q: "",
                                                            }
                                                        })
                                                    }
                                                    value={selectedPONumber}
                                                    isClearable
                                                    onInputChange={val =>
                                                        handleSearch(
                                                            val,
                                                            setPOsListing
                                                        )
                                                    }
                                                    onChange={option =>
                                                        handleSelectPONumber(
                                                            option
                                                        )
                                                    }
                                                    placeholder="Select P.O No."
                                                    classNamePrefix="status-header-search"
                                                    options={posListing?.data}
                                                    // className="w-100 order-man-select"
                                                    isLoading={
                                                        posListing?.fetching
                                                    }
                                                    noOptionsMessage={() => (
                                                        <span>
                                                            No P.O created !
                                                        </span>
                                                    )}
                                                />
                                            </div>
                                            <div
                                            // style={{ width: "200px" }}
                                            // className="me-2"
                                            >
                                                <AutoComplete
                                                    customStyles={
                                                        customStyles.WarehouseIM
                                                    }
                                                    value={statusFilters}
                                                    isClearable
                                                    onChange={val =>
                                                        handleSelectStatusOption(
                                                            val
                                                        )
                                                    }
                                                    isSearchable={false}
                                                    placeholder="Select status"
                                                    classNamePrefix="status-header-search"
                                                    options={convertToSelectOptions(
                                                        InventoryReqItemsStatus
                                                    )}
                                                    // className="w-100 order-man-select"
                                                />
                                            </div>
                                            <SearchBox
                                                isHideLable
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                inputClasses="table-main-search-input wh-im-search-input"
                                                placeholder="Search..."
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: (() =>
                                                    selectedPONumber?.id ? (
                                                        <div className="form-check">
                                                            <TextInput
                                                                onClick={() =>
                                                                    handleSelectAll()
                                                                }
                                                                labelClass="mb-0"
                                                                type="checkbox"
                                                                inputClass="form-check-input-custom"
                                                                checked={
                                                                    !Boolean(
                                                                        data.find(
                                                                            item =>
                                                                                !item.isChecked
                                                                        )
                                                                    )
                                                                    // ||
                                                                    // isAllSelected
                                                                }
                                                            />
                                                        </div>
                                                    ) : null)(),
                                                render: (data, ind) => {
                                                    return selectedPONumber?.id ? (
                                                        <div className="form-check">
                                                            <TextInput
                                                                onClick={() =>
                                                                    handleCheckBoxSelect(
                                                                        data,
                                                                        ind
                                                                    )
                                                                }
                                                                labelClass="mb-0"
                                                                type="checkbox"
                                                                inputClass="form-check-input-custom"
                                                                checked={
                                                                    data.isChecked &&
                                                                    isEligible(
                                                                        data
                                                                    )
                                                                }
                                                                disabled={
                                                                    !isEligible(
                                                                        data
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : null
                                                },
                                            },
                                            {
                                                title: COLUMNS.PO_NO,
                                                render: data => (
                                                    <span>
                                                        {convertArrItemsToCommaSeparatedString(
                                                            data.poNo
                                                        )}
                                                    </span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.SKU_NO,
                                                render: data => (
                                                    <span>{data.skuNo}</span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.ITEM_NAME,
                                                render: data => (
                                                    <span>{data.itemName}</span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.REQUEST_QUANTITY,
                                                render: data => (
                                                    <span>{data.quantity}</span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.RECEIVED_QUANTITY,
                                                render: data => (
                                                    <span>
                                                        {data.receivedQuantity}
                                                    </span>
                                                ),
                                            },
                                            {
                                                title: COLUMNS.QUANTITY_SUPPLIED,
                                                render: data => (
                                                    <span>
                                                        {Math.abs(
                                                            data.quantitySupplied
                                                        )}
                                                    </span>
                                                ),
                                            },

                                            {
                                                title: COLUMNS.SUBSTITUTE_ITEM,
                                                render: data =>
                                                    data?.substitute
                                                        ?.itemName || "-",
                                            },

                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${InventoryReqItemsStatus.getLabelClass(
                                                                data.status
                                                            )}`}
                                                        >
                                                            {InventoryReqItemsStatus.getDisplayTextKey(
                                                                data.status
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTIONS,
                                                render: data => {
                                                    return data.status !=
                                                        InventoryReqItemsStatus.Pending ? (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() => {
                                                                handleNavigateToRequestItemsReceiving(
                                                                    data
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={eyeIcon}
                                                            />
                                                        </span>
                                                    ) : (
                                                        "-"
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center d-flex justify-content-center align-items-center">
                                <Button
                                    disabled={!selectedPONumber?.id}
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment ln-height-1"
                                    title="Received"
                                    onClick={handleInitMarkReceiving}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    isOpen={modalToggle}
                    handleModalToggling={handleInitMarkReceiving}
                    bodyClassName="p-0"
                    customButton={true}
                    hideModalHeaderSeparator={true}
                    headerClasses="pb-0"
                    className="inv-req-popup-viewdetails"
                    sizeClasses="10"
                >
                    <RequestItemsReceivingSection
                        selectedData={() => getAllSelectedItems(data)}
                        onModalToggle={() => {
                            setIsAllSelected(false)
                            setModalToggle(!modalToggle)
                        }}
                        onListRefresh={request}
                        selectedPO={selectedPONumber}
                    />
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default RequestsItemsListing
