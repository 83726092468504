import React, { useState } from "react"
import { CardBody, Card } from "reactstrap"
import { toast } from "react-hot-toast"
import { useSelector } from "react-redux"

import { DataTable } from "components/DataTable"
import PencilIcon from "assets/images/warehouse/inventory-management/pencil-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { TableHeaderButtons } from "components/TableHeaderButtons"
import { PageLimit } from "components/PageLimit"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
    AddNewItem,
    GetItemsListing,
    UpdateItem,
    CSVFileUpload,
    AddSelectedItem,
    UpdateSelectedItem,
} from "api/api.service"
import AddNewItemForm from "./AddNewItemForm"
import MultiAddInventoryModal from "./MultiAddInventoryModal"
import UploadInvFileModal from "./UploadInvFileModal"
import { Refresh } from "components/Refresh"
import { TextInput } from "components/TextInput"
import UploadInvFileListingModal from "./UploadInvFileListingModal"
import UpdateSelectedItemsListingModal from "./UpdateSelectedItemsListingModal"
import SelectedItemsModal from "./SelectedItemsModal "
import UpdateSelectedItemsModal from "./UpdateSelectedItemsModal"
import { UserTypes } from "constants"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
    SKU_NUMBER: "SKU No. ",
    ITEM_NAME: "Item Name",
    ITEM_TYPE: "Item Type",
    ITEM_QTY: "Item Qty",
    PREFERRED_VENDOR: "Preferred Vendor",
    ACTION: "Action",
}
const ACTION_TYPES = {
    VIEW_DETAILS: "VIEW_DETAILS",
    EDIT: "EDIT",
}

const ADD = "add"
const EDIT = "edit"
const MIN_LENGTH_OF_ITEMS = 1

const SYNCHRONIZE_TYPE = "synchronizeType"
const IMPORT = "Import"
const AUDIT = "Audit"

const ItemsListing = ({}) => {
    const userType = useSelector(state => state?.userAuth?.user?.type)
    // const [modalToggle, setModalToggle] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isFileSubmitLoading, setIsFileSubmitLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [actionType, setActionType] = useState(null)
    const [selectedItems, setSelectedItems] = useState([])
    const [csvQuantities, setCsvQuantities] = useState({})
    const [uploadFileListingData, setUploadFileListingData] = useState({
        items: [],
    })
    const [modalStates, setModalStates] = useState({
        modalToggle: false,
        multiAddInventoryModal: false,
        invFileUploadModal: false,
        invFileUploadListingModal: false,
        selectedItemsModal: false,
        editSelectedItemsModal: false,
    })

    const {
        data,
        total,
        isFetching,
        page,
        searchText,
        pageSize,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetItemsListing,
        params: {},
        deps: [],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const isSuperAdmin = userType == UserTypes.SUPER_ADMIN

    const handleCloseModal = () => {
        setModalStates({
            ...modalStates,
            modalToggle: false,
            invFileUploadModal: false,
            multiAddInventoryModal: false,
            invFileUploadListingModal: false,
            selectedItemsModal: false,
            editSelectedItemsModal: false,
        })
        setIsReadOnly(false)
        setSelectedItem({})
        setIsEdit(false)
    }

    const handleMultiAddInvModalClose = () => {
        setModalStates({ ...modalStates, multiAddInventoryModal: false })
    }
    const handleEditSelectedItemsModalClose = () => {
        setModalStates({ ...modalStates, editSelectedItemsModal: false })
    }

    // Add Inventory Modal open handler
    const addInventoryModalOpen = () => {
        setModalStates({
            ...modalStates,
            multiAddInventoryModal: false,
            modalToggle: true,
        })
    }

    const handleInvFileUploadModalOpen = () => {
        setModalStates({
            ...modalStates,
            multiAddInventoryModal: false,
            invFileUploadModal: true,
        })
    }

    const handleInvFileUploadModalClose = () => {
        setModalStates({
            ...modalStates,
            invFileUploadModal: false,
        })
    }

    const fileSubmitHandler = async fileUploaded => {
        let payload = new FormData()
        payload.append("file", fileUploaded)

        if (actionType == ADD) {
            payload.append(SYNCHRONIZE_TYPE, IMPORT)
        } else if (actionType == EDIT) {
            payload.append(SYNCHRONIZE_TYPE, AUDIT)
        }

        try {
            setIsFileSubmitLoading(true)
            const res = await CSVFileUpload(payload)
            setModalStates({
                ...modalStates,
                invFileUploadModal: false,
                // invFileUploadListingModal: true,
                ...(isSuperAdmin && { invFileUploadListingModal: true }),
            })
            setUploadFileListingData(res)
            if (!isSuperAdmin) {
                handleCloseModal()
                request()
            }
            // request()
            // handleInvFileUploadModalClose()
        } catch (err) {
        } finally {
            setIsFileSubmitLoading(false)
        }
    }

    const handleUpdateOrAddInventoryItem = action => {
        if (action == ADD) {
            // setActionType(ADD)
            setModalStates({
                ...modalStates,
                multiAddInventoryModal: true,
            })
        } else if (action == EDIT) {
            // setActionType(EDIT)
            setModalStates({
                ...modalStates,
                ...(selectedRows.length > 0
                    ? { editSelectedItemsModal: true }
                    : { invFileUploadModal: true }),
            })
        }
        setActionType(action)
    }

    const headerButtonsData = [
        {
            title: "Add New Item",
            onClick: () => handleUpdateOrAddInventoryItem(ADD),
            classesButton: "ms-0 ",
            isDisable: false,
        },
        {
            title: "Edit Qty",
            onClick: () => handleUpdateOrAddInventoryItem(EDIT),
            classesButton: "ms-0 ms-md-2",
            isDisable: total < MIN_LENGTH_OF_ITEMS,
        },
    ]

    const handleSelectItem = (action, data) => {
        if (action == ACTION_TYPES.VIEW_DETAILS) {
            setSelectedItem(data)
            setModalStates({
                ...modalStates,
                modalToggle: true,
            })
            setIsReadOnly(true)
            setIsEdit(false)
        } else if (action == ACTION_TYPES.EDIT) {
            setSelectedItem(data)
            setModalStates({
                ...modalStates,
                modalToggle: true,
            })
            setIsEdit(true)
            setIsReadOnly(false)
        }
    }

    const handleAddItem = async payload => {
        if (isReadOnly) {
            handleCloseModal()
            return
        }
        try {
            setIsLoading(true)
            const response = await AddNewItem(payload)
            toast.success("Item has been added successfully.")
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateItem = async (id, payload) => {
        try {
            setIsLoading(true)
            const response = await UpdateItem(id, payload)
            toast.success("Item has been updated successfully.")
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async formData => {
        let id = formData.id
        let payload = {
            itemName: formData.itemName,
            skuNo: formData.skuNo,
            itemType: formData.itemType,
            description: formData.description,
            majorThreshold: Math.abs(formData.mediumThreshold),
            minorThreshold: Math.abs(formData.minorThreshold),
            mediumThreshold: Math.abs(formData.mediumThreshold),
            itemsCategoryId: formData.itemsCategoryId,
            vendorId: formData.itemsVendorId,
            quantity: formData.quantity,
            maxQty: 0,
        }

        if (isEdit) {
            await handleUpdateItem(id, payload)
        } else {
            await handleAddItem(payload)
        }

        !isReadOnly && request()
    }

    const updateFileUploadListingHandler = async formData => {
        const formattedData = uploadFileListingData?.items?.map(item => ({
            id: item.id,
            skuNo: item.skuNo,
            existingQuantity: item.existingItemQuantity || 0,
            newQuantity: Number(item.newQuantity) || 0,
            adjustmentQuantity: item.adjustmentQuantity || 0,
        }))
        const payload = {
            items: formattedData,
            bulkImportId: uploadFileListingData.bulkImportId,
            synchronizeType: AUDIT,
            adjustmentNote: formData.note,
        }

        try {
            setIsLoading(true)
            await AddSelectedItem(payload)
            toast.success("Selected Item has been updated successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }
    const selectedItemHandler = async () => {
        const formattedData = selectedItems?.map(item => ({
            id: item.id,
            skuNo: item.skuNo,
            existingQuantity: item.existingItemQuantity || 0,
            newQuantity: Number(item.newQuantity) || 0,
            adjustmentQuantity: item.adjustmentQuantity || 0,
            price: item.price,
        }))
        const payload = {
            items: formattedData,
            bulkImportId: uploadFileListingData.bulkImportId,
            synchronizeType: IMPORT,
        }
        try {
            setIsLoading(true)
            await AddSelectedItem(payload)
            toast.success("Selected Item has been added successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const selectedItemUpdateHandler = async formData => {
        const formattedData = selectedRows.map(item => ({
            itemsId: item.id,
            quantity: Number(csvQuantities[item.id]) || 0,
        }))

        const payload = {
            items: formattedData,
            adjustmentNote: formData.note,
        }

        try {
            setIsLoading(true)
            await UpdateSelectedItem(payload)
            toast.success("Selected Item has been updated successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleInvFileUploadListingModalClose = () => {
        setModalStates({
            ...modalStates,
            invFileUploadListingModal: false,
        })
    }
    const handleSelectedItemsModalClose = () => {
        setModalStates({ ...modalStates, selectedItemsModal: false })
    }

    const handleBackButtonClick = () => {
        setModalStates({
            invFileUploadListingModal: true,
            selectedItemsModal: false,
        })
    }

    const handleNextButtonClick = selectedItems => {
        setSelectedItems(selectedItems)
        setModalStates(prev => ({
            ...prev,
            selectedItemsModal: true,
        }))
    }

    const handleCheckedBoxSelection = item => {
        setSelectedRows(prevSelectedItems => {
            if (prevSelectedItems.find(selected => selected.id == item.id)) {
                return prevSelectedItems.filter(
                    selected => selected.id != item.id
                )
            } else {
                return [...prevSelectedItems, item]
            }
        })
    }

    const isItemSelected = item => {
        return (
            selectedRows?.find(selected => selected.id == item.id) != undefined
        )
    }

    return (
        <>
            <Card className="mb-5">
                <Refresh onClick={request} isDisable={isFetching} />
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div className="d-flex align-items-center justify-content-end mb-4 mt-2">
                        <div className="d-flex  mt-lg-0 me-0  wh-im-header-inner-btns-con ">
                            <TableHeaderButtons
                                // data={headerButtonsData}
                                data={(function () {
                                    var arr = [
                                        {
                                            title: "Add New Item",
                                            onClick: () =>
                                                handleUpdateOrAddInventoryItem(
                                                    ADD
                                                ),
                                            classesButton: "ms-0 ",
                                            isDisable: false,
                                        },
                                    ]

                                    if (isSuperAdmin) {
                                        arr.splice(1, 0, {
                                            title: "Edit Qty",
                                            onClick: () =>
                                                handleUpdateOrAddInventoryItem(
                                                    EDIT
                                                ),
                                            classesButton: "ms-0 ms-md-2",
                                            isDisable:
                                                total < MIN_LENGTH_OF_ITEMS,
                                        })
                                    }
                                    return arr
                                })()}
                                classes={
                                    "gt-btn-grad-primary px-2 px-md-4 wh-im-header-inner-btns btn-alignment"
                                }
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between  ">
                        <PageLimit
                            pageSize={pageSize}
                            changePageSizeHandler={onChangePageSize}
                            rowLimit={pageSize}
                            typoClasses="page-limit-typo"
                            selectClasses="page-limit-select"
                        />
                        <div>
                            <SearchBox
                                searchText={searchText}
                                onChange={handleSearchChange}
                                inputClasses="table-main-search-input wh-im-search-input"
                            />
                        </div>
                    </div>
                    <DataTable
                        data={data}
                        loading={isFetching}
                        tableClasses="wh-im-table"
                        config={(function () {
                            var arr = [
                                {
                                    title: COLUMNS.SKU_NUMBER,
                                    render: data => {
                                        return <>{data?.skuNo || "-"}</>
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,
                                    render: data => {
                                        return data?.itemName || "-"
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_TYPE,
                                    render: data => {
                                        return data?.itemType || "-"
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_QTY,
                                    render: data => data?.globalQuantity,
                                },
                                {
                                    title: COLUMNS.PREFERRED_VENDOR,
                                    render: data => {
                                        return data?.vendor?.vendorName || "-"
                                    },
                                },

                                {
                                    title: COLUMNS.ACTION,
                                    className: "item-listing-action",
                                    render: data => (
                                        <>
                                            <span className="text-success pointer">
                                                <img
                                                    src={PencilIcon}
                                                    onClick={() =>
                                                        handleSelectItem(
                                                            ACTION_TYPES.EDIT,
                                                            data
                                                        )
                                                    }
                                                />
                                                <img
                                                    src={ActionEyeIcon}
                                                    className="mx-4"
                                                    onClick={() =>
                                                        handleSelectItem(
                                                            ACTION_TYPES.VIEW_DETAILS,
                                                            data
                                                        )
                                                    }
                                                />
                                            </span>
                                        </>
                                    ),
                                },
                            ]

                            if (isSuperAdmin) {
                                arr.splice(0, 0, {
                                    title: COLUMNS.CHECK_BOXES,

                                    render: data => (
                                        <div className=" ">
                                            <TextInput
                                                onClick={() =>
                                                    handleCheckedBoxSelection(
                                                        data
                                                    )
                                                }
                                                labelClass="mb-0"
                                                type="checkbox"
                                                inputClass="form-check-input-custom pointer"
                                                checked={isItemSelected(data)}
                                            />
                                        </div>
                                    ),
                                })
                            }
                            return arr
                        })()}
                    />
                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={modalStates.modalToggle}
                handleModalToggling={handleCloseModal}
                bodyClassName="px-4"
                customButton={true}
                hideModalHeaderSeparator={true}
                sizeClasses="10"
            >
                <AddNewItemForm
                    isNative
                    isReadOnly={isReadOnly}
                    modalCloseHandler={handleCloseModal}
                    title={
                        isEdit
                            ? "Update Item"
                            : isReadOnly
                            ? "Item Details"
                            : "Add New Item"
                    }
                    footerBtnType="Save"
                    handleFormSubmit={handleSubmit}
                    isLoading={isLoading}
                    data={selectedItem}
                    isEditing={isEdit}
                />
            </Modal>
            <Modal
                isOpen={modalStates.multiAddInventoryModal}
                handleModalToggling={handleMultiAddInvModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="sm"
            >
                <MultiAddInventoryModal
                    modalCloseHandler={handleMultiAddInvModalClose}
                    handleInvFileUploadModalOpen={handleInvFileUploadModalOpen}
                    addInventoryModalOpen={addInventoryModalOpen}
                    title="Add Items"
                />
            </Modal>
            <Modal
                isOpen={modalStates.invFileUploadModal}
                handleModalToggling={handleInvFileUploadModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="sm"
            >
                <UploadInvFileModal
                    modalCloseHandler={handleInvFileUploadModalClose}
                    title="ADD ITEMS"
                    fileSubmitHandler={fileSubmitHandler}
                    isFileSubmitLoading={isFileSubmitLoading}
                />
            </Modal>

            <Modal
                isOpen={modalStates.invFileUploadListingModal}
                handleModalToggling={handleInvFileUploadListingModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                // headerClasses="pb-0 "
                size="lg"
            >
                {actionType == ADD && (
                    <UploadInvFileListingModal
                        title={"CSV Inventory"}
                        listingData={uploadFileListingData.items}
                        onNextButtonClick={handleNextButtonClick}
                        loading={isFileSubmitLoading}
                    />
                )}

                {actionType == EDIT && (
                    <UpdateSelectedItemsListingModal
                        title={"Edit Qty"}
                        selectedItems={uploadFileListingData.items}
                        onSend={updateFileUploadListingHandler}
                        loading={isLoading}
                    />
                )}
            </Modal>

            <Modal
                isOpen={modalStates.selectedItemsModal}
                handleModalToggling={handleSelectedItemsModalClose}
                className="inv-req-popup-viewdetails"
                size="lg"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                // headerClasses="pb-0"
            >
                <SelectedItemsModal
                    title={"CSV Inventory"}
                    selectedItems={selectedItems}
                    onClose={handleSelectedItemsModalClose}
                    onSend={selectedItemHandler}
                    onBack={handleBackButtonClick}
                    loading={isLoading}
                />
            </Modal>
            <Modal
                isOpen={modalStates.editSelectedItemsModal}
                handleModalToggling={handleEditSelectedItemsModalClose}
                className="inv-req-popup-viewdetails"
                size="lg"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
            >
                <UpdateSelectedItemsModal
                    title={"Edit Qty"}
                    selectedItems={selectedRows}
                    onClose={handleEditSelectedItemsModalClose}
                    onSend={selectedItemUpdateHandler}
                    csvQuantities={csvQuantities}
                    setCsvQuantities={setCsvQuantities}
                    loading={isLoading}
                />
            </Modal>
        </>
    )
}

export default ItemsListing
