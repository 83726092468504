import { injectBaseConstantMethods } from "./BaseConstants"

const ScrapReturnStatus = {
  Scrap: "Scrap",
  Return: "Return",
}

const displayTextKeys = {
  [ScrapReturnStatus.Scrap]: "Scrap",
  [ScrapReturnStatus.Return]: "Return",
}

const labelClass = {
  [ScrapReturnStatus]: "",
}

export default injectBaseConstantMethods(
  ScrapReturnStatus,
  displayTextKeys,
  labelClass
)
