import { useEffect, useState } from "react"

import Config from "../Config"

const useAsyncSelect = ({
    apiFunc = () => {},
    customExecutionHandlers = null,
    labelKey = "",
    valueKey = "",
    defaultPage = 1,
    params = {},
    restrictExecutionOnRender = false,
    onOptionSelect = () => {},
}) => {
    let controller = new AbortController()
    let signal = controller.signal

    const [selectedOption, setSelectedOption] = useState(null)
    const [selectEntity, setSelectEntity] = useState({
        data: [],
        fetching: false,
        page: defaultPage,
        Q: "",
        isDataAvailable: false,
    })

    useEffect(() => {
        if (!restrictExecutionOnRender) {
            handleGetSelectEntityData(selectEntity.page)
        }
        if (typeof customExecutionHandlers === "function") {
            customExecutionHandlers()
        }
        return () => {
            controller.abort()
        }
    }, [selectEntity.Q])

    const handleSearchOnOptions = value => {
        if (!value) return

        setSelectEntity(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleSelectAOption = (option = "") => {
        setSelectedOption(option)
        onOptionSelect(option)
    }

    const handleGetSelectEntityData = page => {
        setSelectEntity(prev => ({ ...prev, fetching: true, data: [] }))
        apiFunc(
            {
                limit: Config.LIMIT,
                page: page,
                ...(selectEntity.Q && { Q: selectEntity.Q }),
                ...(params && params),
            },
            signal
        )
            .then(res => {
                setSelectEntity(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data.length == Config.LIMIT,
                    data: (() => {
                        let options = res?.data.map(item => ({
                            ...item,
                            value: item[valueKey],
                            label: item[labelKey],
                        }))
                        return [...options, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                setSelectEntity(prev => ({
                    ...prev,
                    fetching: false,
                }))
            })
    }

    return {
        selectEntity,
        selectedOption,
        setSelectEntity,
        handleGetSelectEntityData,
        handleSearchOnOptions,
        handleSelectAOption,
        setSelectedOption,
    }
}

export default useAsyncSelect
