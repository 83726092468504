import { injectBaseConstantMethods } from "./BaseConstants"

const InquiryTypes = {
  SUSPENDED: "suspended",
  GENERAL: "general",
}

const displayTextKeys = {
  [InquiryTypes.SUSPENDED]: "Suspended",
  [InquiryTypes.GENERAL]: "General",
}

const labelClass = {}

export default injectBaseConstantMethods(
  InquiryTypes,
  displayTextKeys,
  labelClass
)
