import React from "react"

import imageUpload from "../../assets/images/ware-house-image-upload-placeholder.png"
const IconComponent = props => {
  const { iconImage = imageUpload, iconClass = "" } = props
  return (
    <>
      <div className="icon-img-container">
        <img src={iconImage} className={`img-general ${iconClass}`} />
      </div>
    </>
  )
}

export default IconComponent
