import { store } from "../store"
import { AuthCode } from "../constants"
import { SIGN_OUT } from "../store/actions"
import toast from "react-hot-toast"

export const handleFetchError = async res => {
    if (
        res.status >= AuthCode.BAD_REQUEST &&
        res.status < AuthCode.CONNECTION_TIMED_OUT
    ) {
        const response = await res.clone().json()
        let errRes = {
            ...response,
            Message: response.Message,
            Status: res.status,
        }
        if (res.status === AuthCode.UNAUTHORIZED) {
            store.dispatch({ type: SIGN_OUT })
            localStorage.clear()
        }
        toast.error(errRes.message)
        throw errRes
    }
    return res.json()
}
