import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import error from "../../assets/images/error-img.png";
import { Button } from "components/Button";

const Page500 = ({ reason = "Internal Server Error", showDashboardRoute = true }) => {
    return (
        <div className="account-pages my-5 pt-5">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="text-center mb-5">
                            <h1 className="display-2 font-weight-medium">
                                5<i className="bx bx-buoy bx-spin text-primary display-3"></i>0
                            </h1>
                            <h4 className="text-uppercase">{reason}</h4>
                            <div className="mt-5 text-center">
                                <Button
                                title="Reload"
                                className="gt-btn-grad-primary"
                                onClick={() => window.location.reload()}
                                />
                            </div>
                            {showDashboardRoute &&
                            <div className="mt-5">
                                <Link to="/dashboard">Go Back To Dashboard</Link>
                            </div>}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="8" xl="6">
                        <div>
                            <img src={error} alt="" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Page500;
