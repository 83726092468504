import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { Row, Col, Input, Form } from "reactstrap"
import toast from "react-hot-toast"

import { Button } from "components/Button"
import {
    GetItemDetailsById,
    ItemCategoriesListing,
    VendorListing,
} from "api/api.service"
import { AutoCompleteStyling } from "../../Common/AutoCompleteStyling"
import { AutoComplete } from "components/AutoComplete"
import { initialValues, validationSchema } from "./FormConfig"
import ItemThresholdSection from "./ItemThresholdSection"
import useAsyncSelect from "hooks/useAsyncSelect"
import { getItemThresholdClass } from "utils/itemThresholdUtil"
import { convertToSelectOptions, hasData } from "utils/commonUtils"
import { InventoryItemType } from "constants"
import { Loader } from "components/Loader"

const ITEM_TYPE = {
    ["General Inventory"]: "General",
    ["Chemicals"]: "Chemicals",
    ["Tools"]: "Tools",
}

const AddNewItemForm = ({
    data = "",
    title = "",
    subTitle = "",
    footerBtnType = "",
    modalCloseHandler = () => {},
    handleFormSubmit = () => {},
    isLoading = false,
    isReadOnly = false,
    isEditing = false,
}) => {
    const [itemDetails, setItemDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [selectedItemType, setSelectedItemType] = useState(null)

    const {
        setSelectedOption: setSelectedVendorOption,
        selectEntity: vendorsOptions,
        setSelectEntity: setVendorsOptions,
        handleSearchOnOptions: handleSearchVendors,
        selectedOption: selectedVendor,
        handleSelectAOption: handleSelectAVendorOption,
    } = useAsyncSelect({
        apiFunc: VendorListing,
        labelKey: "vendorName",
        valueKey: "id",
        restrictExecutionOnRender: isReadOnly,
        onOptionSelect: option => {
            formik.setFieldValue("itemsVendorId", option?.value)
        },
    })
    const {
        setSelectedOption: setSelectedCategoryOption,
        selectEntity: categoryOptions,
        setSelectEntity: setCategoriesOptions,
        handleSearchOnOptions: handleSearchCategories,
        selectedOption: selectedCategory,
        handleSelectAOption: handleSelectACategoryOption,
    } = useAsyncSelect({
        apiFunc: ItemCategoriesListing,
        labelKey: "name",
        valueKey: "id",
        restrictExecutionOnRender: isReadOnly,
        onOptionSelect: option => {
            formik.setFieldValue("itemsCategoryId", option?.value)
        },
    })

    useEffect(() => {
        handleGetItemDetails(data.id)
    }, [data.id])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: values => {
            handleFormSubmit(values)
        },
    })

    const setPreviousData = data => {
        if (!hasData(data) && subTitle) {
            formik.setFieldValue("itemType", ITEM_TYPE[subTitle])
            return
        }

        if (hasData(data)) {
            setSelectedCategoryOption(
                data?.itemsCategory?.id
                    ? {
                          label: data?.itemsCategory?.name,
                          value: data?.itemsCategory?.id,
                      }
                    : null
            )
            setSelectedItemType(
                data.itemType
                    ? {
                          label: data?.itemType,
                          value: data?.itemType,
                      }
                    : null
            )
            setSelectedVendorOption(
                data?.vendor?.id
                    ? {
                          label: data?.vendor?.vendorName,
                          value: data?.vendor?.id,
                      }
                    : null
            )
            formik.setFieldValue("id", data?.id)
            formik.setFieldValue("skuNo", data?.skuNo)
            formik.setFieldValue("itemName", data?.itemName)
            formik.setFieldValue("description", data?.description)
            formik.setFieldValue(
                "majorThreshold",
                data?.itemsConfiguration?.majorThreshold
            )
            formik.setFieldValue(
                "minorThreshold",
                data?.itemsConfiguration?.minorThreshold
            )
            formik.setFieldValue(
                "mediumThreshold",
                data?.itemsConfiguration?.mediumThreshold
            )
            formik.setFieldValue(
                "itemsCategoryId",
                data?.itemsCategory?.id || ""
            )
            formik.setFieldValue("itemsVendorId", data?.vendor?.id || "")
            formik.setFieldValue("itemType", data?.itemType || "")
            formik.setFieldValue("quantity", data?.quantity)
        }
    }
    const handleSelectItemTypeOption = option => {
        setSelectedItemType(option)
        formik.setFieldValue("itemType", option !== null ? option.value : "")
    }

    const handleGetItemDetails = async id => {
        if (!id) return
        try {
            setLoading(true)
            let res = await GetItemDetailsById(id)
            setPreviousData(res)
            setItemDetails(res)
        } catch (err) {
            toast.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className="w-100 h-100 p-4">
                <Loader />
            </div>
        )
    }

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <h3 className="text-center inv-req-popup-heading mb-0">
                    {title}
                </h3>
                <h5 className="text-center inv-req-popup-heading wh-im-dpgi-addnewitem">
                    {subTitle}
                </h5>

                <Row className="mt-3">
                    {/* Items */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label className="">Item</label>

                        <Input
                            disabled={isReadOnly}
                            type="text"
                            name="itemName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.itemName}
                            id="exampleSelect"
                        />
                        {formik.touched.itemName && formik.errors.itemName ? (
                            <span className="error fs-10">
                                {formik.errors.itemName}
                            </span>
                        ) : null}
                    </Col>
                    {/* Category */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label className="">Category</label>
                        <AutoComplete
                            isDisabled={isReadOnly}
                            handleBlur={() =>
                                setCategoriesOptions(prev => {
                                    return {
                                        ...prev,
                                        page: 1,
                                        Q: "",
                                    }
                                })
                            }
                            onChange={handleSelectACategoryOption}
                            onInputChange={handleSearchCategories}
                            placeholder="Search Category"
                            classNamePrefix="add-new-item"
                            customStyles={AutoCompleteStyling}
                            options={categoryOptions?.data}
                            isClearable={true}
                            value={selectedCategory}
                            isLoading={categoryOptions?.fetching}
                        />
                        {formik.touched.itemsCategoryId &&
                        formik.errors.itemsCategoryId ? (
                            <span className="error fs-10">
                                {formik.errors.itemsCategoryId}
                            </span>
                        ) : null}
                    </Col>
                </Row>

                <Row className="mt-3">
                    {/* SKU No. */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label className="">SKU. No.</label>
                        <Input
                            disabled={isReadOnly}
                            type="text"
                            name="skuNo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleChange}
                            value={formik.values.skuNo}
                        />
                        {formik.touched.skuNo && formik.errors.skuNo ? (
                            <span className="error fs-10">
                                {formik.errors.skuNo}
                            </span>
                        ) : null}
                    </Col>

                    {/* {!subTitle && ( */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label>Quantity</label>
                        <Input
                            disabled
                            type="number"
                            name="quantity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.quantity}
                            id="exampleSelect"
                            step="0.0000001"
                            min={0}
                        />
                        {formik.touched.quantity && formik.errors.quantity ? (
                            <span className="error fs-10">
                                {formik.errors.quantity}
                            </span>
                        ) : null}
                    </Col>
                    {/* )} */}
                </Row>

                <Row className="mt-3">
                    {/* Item Type */}
                    {/* {!subTitle && ( */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label className="">Item Type</label>
                        <AutoComplete
                            isDisabled={isReadOnly}
                            value={selectedItemType}
                            isClearable
                            onChange={val => handleSelectItemTypeOption(val)}
                            handleBlur={formik.handleBlur}
                            isSearchable={false}
                            placeholder="Select Item type"
                            customStyles={AutoCompleteStyling}
                            classNamePrefix="add-new-item"
                            options={convertToSelectOptions(InventoryItemType)}
                        />
                        {formik.touched.itemType && formik.errors.itemType ? (
                            <span className="error fs-10">
                                {formik.errors.itemType}
                            </span>
                        ) : null}
                    </Col>
                    {/* )} */}

                    {/* Vendor */}
                    <Col sm="12" xs="12" md="6" lg="6" className="gap-1">
                        <label className="">Vendor</label>
                        <AutoComplete
                            isDisabled={isReadOnly}
                            handleBlur={() =>
                                setVendorsOptions(prev => {
                                    return {
                                        ...prev,
                                        page: 1,
                                        Q: "",
                                    }
                                })
                            }
                            onChange={handleSelectAVendorOption}
                            onInputChange={handleSearchVendors}
                            placeholder="Search vendors"
                            classNamePrefix="add-new-item"
                            customStyles={AutoCompleteStyling}
                            options={vendorsOptions?.data}
                            isClearable={true}
                            value={selectedVendor}
                            isLoading={vendorsOptions?.fetching}
                        />
                        {formik.touched.itemsVendorId &&
                        formik.errors.itemsVendorId ? (
                            <span className="error fs-10">
                                {formik.errors.itemsVendorId}
                            </span>
                        ) : null}
                    </Col>
                </Row>

                <Row className="mt-3">
                    {/* Description */}
                    <Col className="gap-1">
                        <label>Description</label>

                        <Input
                            disabled={isReadOnly}
                            type="textarea"
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleChange}
                            value={formik.values.description}
                            id="exampleEmail"
                            className="textarea-max-width"
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                            <span className="error fs-10">
                                {formik.errors.description}
                            </span>
                        ) : null}
                    </Col>
                </Row>

                {isReadOnly && (
                    <Row className="mt-3">
                        {/* Warehouse / Department Qty */}
                        <Col sm="12" md="6" lg="6" className="gap-1">
                            <div className="d-flex gap-2 align-items-center">
                                <span className="fw-500 ">
                                    Warehouse Quantity:
                                </span>
                                <span
                                    className={`wh-im-global-quantity ${getItemThresholdClass(
                                        itemDetails.warehouseQuantity,
                                        itemDetails?.itemsConfiguration
                                            ?.majorThreshold,
                                        itemDetails?.itemsConfiguration
                                            ?.mediumThreshold,
                                        itemDetails?.itemsConfiguration
                                            ?.minorThreshold
                                    )}`}
                                >
                                    {itemDetails.warehouseQuantity}
                                </span>
                            </div>
                        </Col>

                        <Col sm="12" md="6" lg="6" className="gap-1">
                            <div className="d-flex gap-2  align-items-center">
                                <span className="fw-500 ">
                                    Station Quantity:
                                </span>
                                <span
                                    className={`wh-im-global-quantity ${getItemThresholdClass(
                                        itemDetails.departmentQuantity,
                                        itemDetails?.itemsConfiguration
                                            ?.majorThreshold,
                                        itemDetails?.itemsConfiguration
                                            ?.mediumThreshold,
                                        itemDetails?.itemsConfiguration
                                            ?.minorThreshold
                                    )}`}
                                >
                                    {itemDetails.departmentQuantity}
                                </span>
                            </div>
                        </Col>
                    </Row>
                )}
                {/* Stock Status */}
                <Row className="mt-4">
                    <Col sm="12" md="12" lg="12" className="gap-1">
                        <label className="">Stock Status</label>

                        <ItemThresholdSection
                            isReadOnly={isReadOnly}
                            formik={formik}
                        />
                    </Col>
                </Row>

                {!isReadOnly && (
                    <div className="text-center my-4 d-flex justify-content-center align-items-center">
                        <Button
                            type="submit"
                            isLoading={isLoading}
                            size="lg"
                            className="gt-btn-grad-primary btn-height me-2 btn-alignment"
                            title={<div>{footerBtnType}</div>}
                        />
                    </div>
                )}
            </Form>
        </>
    )
}

export default AddNewItemForm
