export const preventUnwantedInput = event => {
  if (
    event.target.type != "Backspace" &&
    event.target.type === "number" &&
    (event.key === "-" ||
      event.key === "+" ||
      (event.key === 0 && event.target.value.length == 1))
  ) {
    event.preventDefault()
  }
}

export const validateZero = (data = [], key = "") => {
  return Boolean(data.filter(item => item[key] <= 0).length)
}

export const isMinMaxValidate = (actualValue = 0, min = 1, max = 0) => {
  if (actualValue >= min && actualValue <= max) {
    return true
  } else {
    return false
  }
}
