import { injectBaseConstantMethods } from "./BaseConstants"

const PurchaseOrderStatus = {
    PENDING: "Pending",
    ORDER_PLACED: "OrderPlaced",
    PARTIALLY_ORDERED: "PartialOrdered",
    ALL_ORDERED: "AllOrdered",
    ORDERED_SUBSTITUTE: "OrderedSubstitute",
    CANCELLED: "Cancelled",
}

const displayTextKeys = {
    [PurchaseOrderStatus.PENDING]: "Pending",
    [PurchaseOrderStatus.ORDER_PLACED]: "Order Placed",
    [PurchaseOrderStatus.PARTIALLY_ORDERED]: "Partially Ordered",
    [PurchaseOrderStatus.ALL_ORDERED]: "All Ordered",
    [PurchaseOrderStatus.ORDERED_SUBSTITUTE]: "Ordered Substitute",
    [PurchaseOrderStatus.CANCELLED]: "Cancelled",
}

const labelClass = {
    [PurchaseOrderStatus.PENDING]: "fm-bg-light-red",
    [PurchaseOrderStatus.ORDER_PLACED]: "fm-bg-light-green",
    [PurchaseOrderStatus.PARTIALLY_ORDERED]: "fm-bg-light-yellow",
    [PurchaseOrderStatus.ALL_ORDERED]: "fm-bg-light-green",
    [PurchaseOrderStatus.ORDERED_SUBSTITUTE]: "fm-bg-light-blue",
    [PurchaseOrderStatus.CANCELLED]: "fm-bg-light-gray",
}

export default injectBaseConstantMethods(
    PurchaseOrderStatus,
    displayTextKeys,
    labelClass
)
