import React from "react"
import { Spinner } from "reactstrap"

const Loader = props => {
  const {
    classes = "",
    size = "md",
    color = "primary",
    showVerifyText = false,
    position = "justify-content-center align-items-center",
  } = props
  return (
    <div className={`d-flex flex-column ${position} w-100 ${classes}`}>
      <Spinner size={size} color={color} />
      {showVerifyText && <p>Verifying</p>}
    </div>
  )
}

export default Loader
