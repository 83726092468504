import { CHECK_BOX_UPDATE_MODAL_ITEMS } from "store/actions";

const initialState = {
    updateListData: [],
}

export default (state= initialState, action) => {
    // console.log(state, action)
    switch (action.type) {
        case CHECK_BOX_UPDATE_MODAL_ITEMS:
            return {
                ...state, 
                updateListData: action.payload
            }
        default :
            return state
    }
}