import { GetNotifications, MarkNotificationsAsRead } from "api/api.service"

export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT"
export const SET_NOTIFICATION_ENTITY_ID = "SET_NOTIFICATION_ENTITY_ID"

export const getUnreadNotificationCount = () => (dispatch, getState) => {
  return GetNotifications()
    .then(res => {
      dispatch({ type: SET_UNREAD_NOTIFICATION_COUNT, payload: res?.TotalUnreadNotifications })
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const setNotificationEntityId = (entityId) => (dispatch, getState) => {
  dispatch({ type: SET_NOTIFICATION_ENTITY_ID, payload: entityId })
}

export const markNotificationsAsRead = ({NotificationIds = []}) => (dispatch, getState) => {
  return MarkNotificationsAsRead({NotificationIds})
    .then(res => {
      dispatch({ type: SET_UNREAD_NOTIFICATION_COUNT, payload: res?.UnreadCount })
      return Promise.resolve(res)
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
