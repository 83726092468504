import React, { useEffect, useState } from "react"

import { DataTable } from "components/DataTable"
import { UsedCount } from "components/UsedCount"

const COLUMNS = {
    ITEM_NAME: "Item Name",
    SKU_NO: "SKU No.",
    COMMITTED: "Committed / Available",
}

const CommittedInventoryListing = ({
    data = {},
    value = "",
    onChange = () => {},
}) => {
    const [itemData, setItemData] = useState({})

    useEffect(() => {
        setItemData([data])
    }, [])

    return (
        <>
            <h4 className="text-center mb-4">COMMIT INVENTORY</h4>
            <DataTable
                data={itemData}
                className="view-detail-inv-req-table"
                // loading = {isFetching}
                config={[
                    {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                            return data?.skuNo
                        },
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                            return data?.itemName
                        },
                    },

                    {
                        title: COLUMNS.COMMITTED,
                        render: data => {
                            return (
                                <UsedCount
                                    max={data.committed}
                                    value={value}
                                    onChange={onChange}
                                    total={data.committed}
                                />
                            )
                        },
                    },
                ]}
            />
        </>
    )
}

export default CommittedInventoryListing
