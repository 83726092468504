import { injectBaseConstantMethods } from "./BaseConstants"

const InventoryItemType = {
    General: "General",
    Chemicals: "Chemicals",
    Tools: "Tools",
}

const displayTextKeys = {
    [InventoryItemType.General]: "General",
    [InventoryItemType.Chemicals]: "Chemicals",
    [InventoryItemType.Tools]: "Tools",
}

const labelClass = {}

export default injectBaseConstantMethods(
    InventoryItemType,
    displayTextKeys,
    labelClass
)
