import { injectBaseConstantMethods } from "./BaseConstants"

const Status = {
  MOCKUP_PENDING: "mockup_pending",
  MOCKUP_READY: "mockup_ready",
  PRODUCTION_PENDING: "production_pending",
  PRODUCTION_READY: "production_ready",
  COMPLETED: "completed",
  CANCELED: "canceled",
}

const displayTextKeys = {
  [Status.MOCKUP_PENDING]: "Mockups Pending",
  [Status.MOCKUP_READY]: "Mockups Ready",
  [Status.PRODUCTION_PENDING]: "Production Pending",
  [Status.PRODUCTION_READY]: "Production Ready",
  [Status.COMPLETED]: "Completed",
  [Status.CANCELED]: "Cancelled",
}

const labelClass = {
  [Status.MOCKUP_PENDING]: "mockups-pending",
  [Status.MOCKUP_READY]: "mockups-ready",
  [Status.PRODUCTION_PENDING]: "production-pending",
  [Status.PRODUCTION_READY]: "production-ready",
  [Status.COMPLETED]: "completed",
  [Status.CANCELED]: "cancelled",
}

export default injectBaseConstantMethods(Status, displayTextKeys, labelClass)
