import { SET_DEVICE_ID } from "store/actions"

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE_ID:
      return action.deviceId
    default:
      return state
  }
}
