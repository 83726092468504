import React from "react"

const FormikErrorText = props => {
  let { formikInstance, fieldName, errorClasses = "" } = props

  return (
    <>
      {formikInstance.touched[fieldName] && formikInstance.errors[fieldName] ? (
        <span className={`error ${errorClasses}`}>
          {formikInstance.errors[fieldName]}
        </span>
      ) : null}
    </>
  )
}

export default FormikErrorText
