import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { APP_ROUTES } from "helpers/routeHelpers"

export default (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
      const { isAuthenticated } = this.props
      if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />
      }
      return <Component {...this.props} />
    }
  }
  const mapStateToProps = state => {
    return {
      isAuthenticated: state.userAuth.isAuthenticated,
    }
  }
  return connect(mapStateToProps)(WithAuthentication)
}
