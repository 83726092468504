import { SET_UNREAD_NOTIFICATION_COUNT, SET_NOTIFICATION_ENTITY_ID } from "../actions"

const initialState = {
  unreadNotificationCount: null,
  notificationEntityId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_NOTIFICATION_COUNT:
      return {
        ...state,
        unreadNotificationCount: action?.payload,
      }
    case SET_NOTIFICATION_ENTITY_ID:
      return {
        ...state,
        notificationEntityId: action?.payload,
      }
    default:
      return state
  }
}
