import { injectBaseConstantMethods } from "./BaseConstants"

const Status = {
  IN_TRANSIT: 1,
  DELIVERED: 2,
  PENDING: 3,
  VERIFIED: 4,
  CANCELLED: 5,
}

const displayTextKeys = {
  [Status.IN_TRANSIT]: "In Transit",
  [Status.DELIVERED]: "Delivered",
  [Status.PENDING]: "Pending",
  [Status.VERIFIED]: "Verified",
  [Status.CANCELLED]: "Cancelled",
}

const labelClass = {
  [Status.IN_TRANSIT]: "intransit",
  [Status.DELIVERED]: "delivered",
  [Status.PENDING]: "pending",
  [Status.VERIFIED]: "verified",
  [Status.CANCELLED]: "cancelled",
}

export default injectBaseConstantMethods(Status, displayTextKeys, labelClass)
